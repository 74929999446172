<template>
  <div
    v-if="brand === 'srp'"
    class="brand-logo brand-srp"
  >
    <b-link to="/">
      <b-img
        :src="srpBrand.appLogo"
        alt="StructrPro"
        width="200px"
      />
    </b-link>
    <b-link
      class="mt-2"
      to="/"
    >
      <span class="brand-text">Powered by</span>
      <b-img
        :src="srpBrand.companyLogo1"
        alt="SRP"
        class="ml-1"
        style="max-width: 54px;"
      />
      <b-img
        v-if="srpBrand.companyLogo2 !==''"
        :src="srpBrand.companyLogo2"
        alt="FVC"
        class="ml-1"
        style="max-width: 54px"
      />
    </b-link>
  </div>
  <b-link
    v-else
    class="brand-logo align-items-center"
    to="/"
  >
    <b-img
      :src="seBrand.companyLogo1"
      alt="logo"
      class="mr-1"
      style="max-width: 72px"
    />
    <b-img
      v-if="seBrand.companyLogo2 !==''"
      :src="seBrand.companyLogo2"
      alt="logo"
      class="mr-1"
      style="max-width: 72px"
    />
    <h2 class="brand-text text-primary mb-0">
      {{ appName }}
    </h2>
  </b-link>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import BrandLogosMixin from '@/mixins/BrandLogosMixin.vue'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // Brand details
    const {
      brand, appName, appLogoImage, companyLogoImage1, companyLogoImage2,
    } = $themeConfig.app
    return {
      brand,
      appName,
      appLogoImage,
      companyLogoImage1,
      companyLogoImage2,
    }
  },
  mixins: [BrandLogosMixin],
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
